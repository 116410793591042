import './chunk-ce0fbc82.js';
import { X } from './chunk-b4205b57.js';
export { X as default } from './chunk-b4205b57.js';
import './chunk-5decc758.js';

var _extend = X._extend;
var callbackify = X.callbackify;
var debuglog = X.debuglog;
var deprecate = X.deprecate;
var format = X.format;
var inherits = X.inherits;
var inspect = X.inspect;
var isArray = X.isArray;
var isBoolean = X.isBoolean;
var isBuffer = X.isBuffer;
var isDate = X.isDate;
var isError = X.isError;
var isFunction = X.isFunction;
var isNull = X.isNull;
var isNullOrUndefined = X.isNullOrUndefined;
var isNumber = X.isNumber;
var isObject = X.isObject;
var isPrimitive = X.isPrimitive;
var isRegExp = X.isRegExp;
var isString = X.isString;
var isSymbol = X.isSymbol;
var isUndefined = X.isUndefined;
var log = X.log;
var promisify = X.promisify;
var types = X.types;

const TextEncoder = X.TextEncoder = self.TextEncoder;
const TextDecoder = X.TextDecoder = self.TextDecoder;

export { TextDecoder, TextEncoder, _extend, callbackify, debuglog, deprecate, format, inherits, inspect, isArray, isBoolean, isBuffer, isDate, isError, isFunction, isNull, isNullOrUndefined, isNumber, isObject, isPrimitive, isRegExp, isString, isSymbol, isUndefined, log, promisify, types };
